import { useRouter } from 'next/router';

import { AuthSessionStorage, ConnectedSocialAccount } from '@domains/auth-common';

import { web_path } from 'constants/web_path';

interface CheckPasswordExistedInput {
  has_password: boolean;
  type: string;
  email: string;
  is_masked_email?: boolean;
  connected_social_account: ConnectedSocialAccount;
  redirect_url?: string;
  error_code: string;
}

export const useSocialAccountError = () => {
  const { push } = useRouter();

  const redirectUrl = (auth_type: string, url: string) => {
    if (auth_type === 'kakao' || auth_type === 'toss') {
      window.location.replace(url);
    } else {
      push(url);
    }
  };

  const checkPasswordExisted = (input: CheckPasswordExistedInput) => {
    const { has_password, type, email, is_masked_email, connected_social_account, redirect_url, error_code } = input;
    let duplicate_sns_account = false;
    if (connected_social_account) {
      Object.keys(connected_social_account).forEach((key) => {
        if (key.includes(type) && connected_social_account[key]) {
          duplicate_sns_account = true;
        }
      });
    }

    const queryParams = new URLSearchParams({
      type,
      email,
      ...(is_masked_email && { is_masked_email: String(is_masked_email) }),
      ...(redirect_url && { redirect: redirect_url }),
    }).toString();

    // 이메일 중복인 경우
    if (error_code === 'user_account_email_exists') {
      // 비밀번호가 없거나 로그인/회원가입 하려는 SNS와 같은 타입으로 이미 SNS가 가입되어 있는 경우, 비밀번호 설정으로 이동
      if (!has_password || duplicate_sns_account) {
        redirectUrl(type, web_path.auth.sign_up_exist_account);
      } else {
        // 이메일로 연결하기로 이동
        const url = `${web_path.auth.connect_account}?${queryParams}`;
        redirectUrl(type, url);
      }
    } else {
      // 비밀번호가 없거나 로그인/회원가입 하려는 SNS와 같은 타입으로 이미 SNS가 가입되어 있는 경우, SNS연결로 이동
      if (!has_password || duplicate_sns_account) {
        AuthSessionStorage.saveConnectedSocialAccount(connected_social_account);
        const url = `${web_path.auth.duplicate_social_account}?${queryParams}`;
        redirectUrl(type, url);
      } else {
        // 이메일로 연결하기로 이동
        const url = `${web_path.auth.connect_account}?${queryParams}`;
        redirectUrl(type, url);
      }
    }
  };

  const parseError = (error, type: string) => {
    const code = error?.extensions?.code;
    const has_password = error?.extensions?.has_password ?? true;

    /** 이미 소셜 계정의 이메일로 가입한 계정이 있을 경우 */
    if (code === 'user_account_email_exists' || code === 'user_account_mobile_tel_exists') {
      const social_account_info = {
        kakao_connected: error?.extensions?.kakao_connected,
        facebook_connected: error?.extensions?.facebook_connected,
        google_connected: error?.extensions?.google_connected,
        apple_connected: error?.extensions?.apple_connected,
        toss_connected: error?.extensions?.toss_connected,
      };
      checkPasswordExisted({
        has_password,
        type,
        email: error.extensions?.email ?? '',
        connected_social_account: social_account_info,
        is_masked_email: code === 'user_account_mobile_tel_exists',
        error_code: code,
      });
      return;
    }
    const queryParams = new URLSearchParams({
      type: code === 'deleted_user_account_within_the_period' ? 'rejoin' : 'signup',
      rejoinPeriod: code === 'deleted_user_account_within_the_period' ? error?.extensions?.remaining_days : undefined,
      message: error?.message ?? '다른 방법으로 다시 시도해주세요.',
    });

    const url = `${web_path.auth.error}?${queryParams.toString()}`;
    push(url);
  };

  return { checkPasswordExisted, parseError };
};
