import { PlusFriendsType } from 'api2/types';

import { useTracker } from '@common/log-manager';
import { airbridgeTracker } from '@common/marketing-tracker';
import { SocialAccount } from '@domains/auth-common';

import config from 'util/config';
import {
  removeGa4UserId,
  setAFCustomerUserId,
  setGa4UserId,
  trackAppierLog,
  trackAppsflyerPba,
  trackGa4,
  trackRegistrationNaverLog,
} from 'util/tracking';

const trackAppierLoginEvent = (email: string) => {
  trackAppierLog([
    {
      t: 'type_login' as const,
      ids: [{ idtype: 'email_sha256', content: email, needSHA256: true }],
      action_id: 'UserLogin_f855',
      track_id: config.appier_track_id,
      opts: { uu: email },
    },
  ]);
};

export const useAuthWebEvent = () => {
  const tracker = useTracker();

  const clickAppDownloadBannerLog = () =>
    tracker.sendImmediately({
      category: 'click',
      navigation: 'login',
      object_type: 'banner',
      object_id: 'app_download',
    });

  const addEmailSignupEvent = (uuid: string, email: string) => {
    setAFCustomerUserId(uuid);
    trackAppsflyerPba({
      eventType: 'EVENT',
      eventName: 'af_complete_registration',
    });
    setGa4UserId(uuid);
    trackGa4('sign_up', {
      method: 'email',
    });
    trackAppierLoginEvent(email);
    trackRegistrationNaverLog();

    airbridgeTracker.setUserId(uuid);
    airbridgeTracker.events.send('airbridge.user.signup', {
      action: 'email',
      semanticAttributes: {
        type: 'email',
      },
    });
  };

  const addEmailLoginEvent = (uuid: string, email: string) => {
    setAFCustomerUserId(uuid!);
    trackAppsflyerPba({
      eventType: 'EVENT',
      eventName: 'af_login',
    });
    setGa4UserId(uuid);
    trackGa4('login', {
      method: 'email',
    });
    trackAppierLoginEvent(email);
    airbridgeTracker.setUserId(uuid);
    airbridgeTracker.events.send('airbridge.user.signin', {
      action: 'email',
      semanticAttributes: {
        type: 'email',
      },
    });
  };

  const addKakaoSignupEvent = (uuid: string, plusFriendsStatus: PlusFriendsType | null) => {
    setAFCustomerUserId(uuid);
    setGa4UserId(uuid);
    trackGa4('sign_up', {
      method: 'kakao',
    });
    trackRegistrationNaverLog();

    airbridgeTracker.setUserId(uuid);
    airbridgeTracker.events.send('airbridge.user.signup', {
      action: 'kakao',
      semanticAttributes: {
        type: 'kakao',
      },
    });
    airbridgeTracker.events.send('signup_kakao', {
      action: plusFriendsStatus === null ? 'null' : plusFriendsStatus,
      semanticAttributes: { type: plusFriendsStatus },
    });
  };

  const addKakaoLoginEvent = (uuid: string, email) => {
    setAFCustomerUserId(uuid!);
    trackAppsflyerPba({
      eventType: 'EVENT',
      eventName: 'af_login',
    });
    trackAppsflyerPba({
      eventType: 'EVENT',
      eventName: 'af_login_kakao',
    });
    setGa4UserId(uuid);
    trackGa4('login', {
      method: 'kakao',
    });
    trackAppierLoginEvent(email);

    airbridgeTracker.setUserId(uuid);
    airbridgeTracker.events.send('airbridge.user.signin', {
      action: 'kakao',
      semanticAttributes: {
        type: 'kakao',
      },
    });
    airbridgeTracker.events.send('signin_kakao');
  };

  const addLogoutEvent = () => {
    setAFCustomerUserId('');
    removeGa4UserId();

    airbridgeTracker.events.send('airbridge.user.signout');
  };

  /** @description 카카오톡 회원가입 제외한 나머지 소셜 계정 (메타, 구글, 애플) */
  const addSocialSignupEvent = (userAccount: { email: string; uuid: string }, type: SocialAccount) => {
    const { email, uuid } = userAccount;
    trackRegistrationNaverLog();
    trackAppierLoginEvent(email);

    if (type === 'email' || type === 'kakao') {
      return;
    }

    airbridgeTracker.setUserId(uuid);
    airbridgeTracker.events.send('airbridge.user.signup', {
      action: type,
      semanticAttributes: {
        type,
      },
    });
  };

  /** @description 카카오톡 회원가입 제외한 나머지 소셜 계정 (메타, 구글, 애플) */
  const addSocialLoginEvent = (userAccount: { email: string; uuid: string }, type: SocialAccount) => {
    const { email, uuid } = userAccount;
    trackAppierLoginEvent(email);

    if (type === 'email' || type === 'kakao') {
      return;
    }

    airbridgeTracker.setUserId(uuid);
    airbridgeTracker.events.send('airbridge.user.signin', {
      action: type,
      semanticAttributes: {
        type,
      },
    });
  };

  return {
    clickAppDownloadBannerLog,
    addEmailSignupEvent,
    addEmailLoginEvent,
    addKakaoSignupEvent,
    addKakaoLoginEvent,
    addLogoutEvent,
    addSocialSignupEvent,
    addSocialLoginEvent,
  };
};
