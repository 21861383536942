import { useCallback, useEffect, useMemo, useState } from 'react';
import { Namespace, useTranslation } from 'react-i18next';

import { I18nKey } from '@common/i18n';
import { IllustCompleted, IllustWarning } from '@common/icons';

import { OverlayCompProps } from '../common/util/createOverlayComp';
import { BottomDialog } from './dump/BottomDialog';
import { ConfirmReturnValue, DialogMessage, DialogMessageObject } from './Dialog';

function RecordConfirm({
  message,
  cancel,
  ok,
  title,
  type,
  resolve,
  dispose,
}: DialogMessageObject & OverlayCompProps<ConfirmReturnValue>) {
  const [active, setActive] = useState(true);
  const { t } = useTranslation('common');
  const proceed = useCallback(
    (value: ConfirmReturnValue) => {
      resolve?.(value);
      setActive(false);
      dispose?.();
    },
    [resolve, setActive, dispose],
  );
  const image = useMemo(() => {
    if (type === 'warning') {
      return <IllustWarning />;
    }
    if (type === 'complete') {
      return <IllustCompleted />;
    }
    return undefined;
  }, [type]);
  const handleOutsideClick = useCallback(() => {
    proceed('skip');
  }, [proceed]);

  useEffect(() => {
    return () => {
      dispose?.();
    };
  }, [dispose]);

  return (
    <BottomDialog
      active={active}
      image={image}
      title={title}
      text={message}
      handleOutsideClick={handleOutsideClick}
      buttons={[
        {
          id: 'cancel',
          children: cancel || t('취소'),
          onClick: () => {
            proceed('cancel');
          },
          variant: 'default',
          size: 'lg',
        },
        {
          id: 'ok',
          children: ok || t('확인'),
          onClick: () => {
            proceed('ok');
          },
          size: 'lg',
        },
      ]}
    />
  );
}

export function renderConfirm<N extends Namespace>(message: DialogMessage, ns?: N) {
  return ({ resolve, dispose }: OverlayCompProps<ConfirmReturnValue>) => {
    if (typeof message === 'object') {
      return ns ? (
        <RecordConfirmWithT {...{ ...message, resolve, dispose, ns }} />
      ) : (
        <RecordConfirm {...{ ...message, resolve, dispose }} />
      );
    }
    return ns ? (
      <RecordConfirmWithT {...{ message, resolve, dispose, ns }} />
    ) : (
      <RecordConfirm {...{ message, resolve, dispose }} />
    );
  };
}

function RecordConfirmWithT<N extends Namespace>({
  title,
  message,
  ok,
  cancel,
  ns,
  ...props
}: DialogMessageObject & OverlayCompProps<ConfirmReturnValue> & { ns: N }) {
  const { t } = useTranslation(ns);
  const { t: tc } = useTranslation('common');
  return (
    <RecordConfirm
      {...{
        ...props,
        title: title && t(title as I18nKey<N>),
        message: t(message as I18nKey<N>),
        ok: ok ? t(ok as I18nKey<N>) : tc('확인'),
        cancel: cancel ? t(cancel as I18nKey<N>) : tc('취소'),
      }}
    />
  );
}
