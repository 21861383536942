import { useSocialAccountError } from 'components/auth/hooks/sign-up';
import { useRouter } from 'next/router';

import { isToss } from '@common/device-manager';
import { useUBL } from '@common/log-manager';
import { Dialog } from '@common/overlay-manager';
import { AuthInfo, AuthSessionStorage, getSocialConnectionName } from '@domains/auth-common';

import { web_path } from 'constants/web_path';

export const useLoginErrors = () => {
  const { push, replace } = useRouter();
  const { checkPasswordExisted } = useSocialAccountError();
  const tracker = useUBL();
  const is_toss = isToss();

  const moveToSignUpPage = (auth_info: AuthInfo) => {
    const url = web_path.auth.terms_agreement;
    AuthSessionStorage.saveAuthInfo(auth_info);
    if (auth_info.type === 'kakao') {
      window.location.replace(web_path.auth.mobile_authentication);
    } else {
      push(url);
    }
  };

  const moveToWriteEmailPage = (auth_info: AuthInfo) => {
    AuthSessionStorage.saveAuthInfo(auth_info);
    push(`${web_path.auth.write_email}?social_account=facebook`);
  };

  const moveToConnectAccountPage = (error: any, auth_info: AuthInfo) => {
    const error_code = error?.extensions?.code;
    const has_password = error?.extensions?.has_password ?? true;
    const connected_social_account = {
      kakao_connected: error?.extensions?.kakao_connected,
      facebook_connected: error?.extensions?.facebook_connected,
      google_connected: error?.extensions?.google_connected,
      apple_connected: error?.extensions?.apple_connected,
      toss_connected: error?.extensions?.toss_connected,
    };
    const redirect_url = AuthSessionStorage.getRedirectUrl();
    AuthSessionStorage.saveAuthInfo(auth_info);
    AuthSessionStorage.saveEmail(error?.extensions?.email ?? '');

    checkPasswordExisted({
      type: auth_info.type,
      has_password,
      email: error?.extensions?.email ?? '',
      connected_social_account,
      is_masked_email: error_code === 'user_account_mobile_tel_exists',
      redirect_url,
      error_code,
    });
  };

  const moveToMobileAuthentication = (auth_info: AuthInfo) => {
    AuthSessionStorage.saveAuthInfo(auth_info);
    replace(web_path.auth.mobile_authentication);
  };

  const checkLoginError = async (error: any, auth_info: AuthInfo, from_duplicate_account?: boolean) => {
    if (is_toss) {
      replace(`${web_path.auth.error}?type=toss`);
      return;
    }

    const error_code = error?.extensions?.code;

    /** 사용자계정이 비활성화 계정인 경우 */
    if (error_code === 'auth_inactive_user_account') {
      replace(web_path.auth.activate_account);
      return;
    }

    // sns 로그인 오류가 여러번 발생시
    if (error_code === 'social_login_error_multiple_times') {
      const queryParams = new URLSearchParams({
        ...(error?.message && { message: error?.message }),
      });

      const url = `${web_path.auth.error_sns}${error?.message ? '?' + queryParams.toString() : ''}`;
      return auth_info.type === 'toss' ? window.location.replace(url) : push(url);
    }

    // 아래 경로에서 로그인 에러 발생시 더 진행하지 않고 알럿 띄움
    const stopLoginErrorProcessPaths = [
      web_path.auth.duplicate_social_account,
      web_path.auth.find_account_by_authentication,
      web_path.auth.find_account_by_tel,
      web_path.auth.find_account_result,
    ];

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const pathname = window?.location?.pathname;
    if (from_duplicate_account || stopLoginErrorProcessPaths.includes(pathname)) {
      tracker({
        category: 'pageview',
        navigation: 'different_account_connected',
        data: { is_opened: true },
      });

      await Dialog.alert({
        title: '해당 계정으로 연결되어 있지 않아요',
        message: `로그인 하려는 계정이 기존 연결된 ${getSocialConnectionName(auth_info.type)} 계정이 아니에요.`,
        ok: '확인',
        variant: 'primary',
      });

      tracker({
        category: 'click',
        navigation: 'different_account_connected',
        object_type: 'button',
        object_id: 'confirm',
      });

      // 카카오의 경우 웹 주소가 변경되어서 뒤로 가는 코드 강제 추가
      // https://croquis.atlassian.net/browse/QM-7479
      // 토스도 웹에서 주소가 변경 되는 상황이 있음
      if (auth_info.type === 'kakao' || auth_info.type === 'toss') {
        window.history.back();
      }
      return;
    }

    if (error_code === 'user_account_not_found') {
      if (pathname === web_path.auth.duplicate_social_account) {
        await Dialog.alert({
          title: '해당 계정으로 연결되어 있지 않아요',
          message: `로그인한 계정이 기존 연결된 ${getSocialConnectionName(auth_info.type)} 계정이 아니에요.`,
          ok: '확인',
          variant: 'primary',
        });
        return;
      }

      moveToSignUpPage(auth_info);
      return;
    }

    if (error_code === 'user_account_email_exists' || error_code === 'user_account_mobile_tel_exists') {
      moveToConnectAccountPage(error, auth_info);
      return;
    }

    if (error_code === 'user_account_invalid_email') {
      moveToWriteEmailPage(auth_info);
      return;
    }

    // 카카오톡 가입이면서 전화 번호가 없는 경우
    if (error_code === 'kakao_mobile_tel_not_found') {
      moveToMobileAuthentication(auth_info);
      return;
    }

    AuthSessionStorage.saveAuthInfo(auth_info);
    // user_account_authentication_identity_cannot_be_changed 는 토스 추가로 ci 중복으로 인한 에러 발생
    // user_account_restricted_under_age_policy 는 토스 추가로 만 14세 미만 회원가입 제한으로 인한 에러 발생
    // 따라서 로그인이 아닌 회원가입 시 에러로 처리
    const queryParams = new URLSearchParams({
      type:
        error_code === 'user_account_authentication_different_identity' ||
        error_code === 'user_account_restricted_under_age_policy'
          ? 'signup'
          : 'login',
      message: error?.message ?? '다른 방법으로 다시 시도해주세요.',
    });

    const url = `${web_path.auth.error}?${queryParams.toString()}`;
    auth_info.type === 'toss' ? window.location.replace(url) : push(url);
  };

  return { checkLoginError };
};
