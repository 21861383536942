import { isDomesticShippingUseCookie } from 'util/address_utils';
import config from 'util/config';
import { isZigzag } from 'util/device';

declare global {
  interface Window {
    appier_q?: any[];
  }
}

/**
 * 애피어 트래킹 (https://docs.google.com/document/d/1hf0SkuUhFY4asr97ZGVJUOAdTYAgkCwVx24sMG1TNtg/edit)
 */
interface Event {
  t: EventType;
  [key: string]: any;
}
type EventType =
  | 'register'
  | 'pv_track'
  | 'type_home'
  | 'type_login'
  | 'type_listpage'
  | 'type_product'
  | 'type_addcart'
  | 'type_cart'
  | 'type_purchase';

export const trackAppierLog = (event_list: Event[]) => {
  if (!isZigzag() && config.appier_id && isDomesticShippingUseCookie()) {
    window.appier_q = window.appier_q || [];
    window.appier_q.push({ t: 'register', content: { id: config.appier_id, site: 'zigzag.kr' } }, ...event_list);
  }
};
