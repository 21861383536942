import { isDomesticShippingUseCookie } from 'util/address_utils';
import config from 'util/config';
import { isZigzag } from 'util/device';

declare global {
  interface Window {
    wcs?: {
      cnv: (type: EventType, value: any) => any;
      inflow: () => void;
    };
    _nasa?: Record<string, any>;
    wcs_do?: (data: Record<string, any>) => void;
  }
}

/**
 * 네이버 프리미엄 로그 (https://saedu.naver.com/help/faq/ncc/view.naver?faqSeq=128)
 * 로그 삽입 문서 (https://www.notion.so/croquis/a9a459bb5bb94b7d96eb5f171a700a28)
 * 1: 구매완료
 * 2: 회원가입
 * 3: 장바구니 담기
 * 4: 신청/예약
 * 5: 기타
 */
type EventType = '1' | '2' | '3' | '4' | '5';

export const trackRegistrationNaverLog = () => {
  if (
    !isZigzag() &&
    config.naver_account_id &&
    window.wcs !== undefined &&
    window.wcs_do !== undefined &&
    isDomesticShippingUseCookie()
  ) {
    const _nasa: Record<string, any> = {};
    _nasa.cnv = window.wcs.cnv('2', '1');
    window.wcs_do(_nasa);
  }
};

export const trackOrderCompletedNaverLog = (total_amount: number) => {
  if (
    !isZigzag() &&
    config.naver_account_id &&
    window.wcs !== undefined &&
    window.wcs_do !== undefined &&
    isDomesticShippingUseCookie()
  ) {
    const _nasa: Record<string, any> = {};
    _nasa.cnv = window.wcs.cnv('1', total_amount);
    window.wcs_do(_nasa);
  }
};
