/* eslint @nx/enforce-module-boundaries: 'warn' */
import { Namespace } from 'react-i18next';

import { I18nKey } from '@common/i18n';
import { RoundFilledVariant } from '@common/toolbox';

import { createOverlayComp } from '../common/util/createOverlayComp';
import { renderAlert } from './alert';
import { renderConfirm } from './confirm';

export type DialogType = 'complete' | 'warning';
export interface DialogMessageObject {
  title?: string;
  message: string;
  ok?: string;
  cancel?: string;
  type?: DialogType;
  variant?: RoundFilledVariant;
}

export interface DialogMessageWithTObject<N extends Namespace> {
  title?: I18nKey<N>;
  message: I18nKey<N>;
  ok?: I18nKey<N>;
  cancel?: I18nKey<N>;
  type?: DialogType;
}
export type DialogMessageObjectComb<NS extends Namespace> = DialogMessageObject | DialogMessageWithTObject<NS>;
export type DialogMessage = string | DialogMessageObject;
export type DialogMessageWithT<N extends Namespace> = I18nKey<N> | DialogMessageWithTObject<N>;

export type AlertReturnValue = 'ok' | 'skip';
const alertDialog = createOverlayComp<AlertReturnValue, DialogMessage>(renderAlert);
export type ConfirmReturnValue = 'ok' | 'skip' | 'cancel';
const confirmDialog = createOverlayComp<ConfirmReturnValue, DialogMessage>(renderConfirm);

/**
 * 앱에서 사용 중인 bottom-sheet dialog와 인터페이스를 맞춤
 * https://github.com/croquiscom/zigzag-www/blob/master/packages/web2app/src/zpay/popup/dialog/index.ts
 */
export class Dialog {
  static alert(message: DialogMessage) {
    return alertDialog(message);
  }
  static alertWithT<N extends Namespace>(ns: N, message: DialogMessageWithT<N>) {
    return alertDialog(message as DialogMessage, ns);
  }
  static confirm(message: DialogMessage) {
    return confirmDialog(message);
  }
  static confirmWithT<N extends Namespace>(ns: N, message: DialogMessageWithT<N>) {
    return confirmDialog(message as DialogMessage, ns);
  }
}
