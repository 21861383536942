import { isDomesticShippingUseCookie } from 'util/address_utils';
import { isZigzag } from 'util/device';

declare global {
  interface Window {
    AF: (type: string, event: string, params: any) => void;
  }
}

type AppsflyerPbaEventType =
  | 'af_complete_registration' // 회원가입
  | 'af_content_view' // 상품 페이지 방문
  | 'purchase_zpay' // Z결제 주문완료
  | 'af_initiated_checkout' // 주문서에서 결제하기 클릭
  | 'af_add_to_cart' // 장바구니 담기
  | 'af_coupon_apply' // 쿠폰 적용
  | 'af_login' // 전체 로그인
  | 'first_purchase' // 첫 구매
  | 'af_list_view' // 목록 뷰
  | 'remove_from_cart' // 장바구니 삭제
  | 'af_search' // 검색
  | 'af_login_kakao';

interface Event {
  eventType?: string;
  eventValue?: object;
  eventName?: AppsflyerPbaEventType;
  eventRevenue?: string;
  eventRevenueCurrency?: string;
}

export const trackAppsflyerPba = (params: Event) => {
  if (!isZigzag() && window.AF !== undefined && isDomesticShippingUseCookie()) {
    window.AF('pba', 'event', params);
  }
};

export const setAFCustomerUserId = (uuid: string) => {
  if (!isZigzag() && window.AF !== undefined) {
    window.AF('pba', 'setCustomerUserId', uuid);
  }
};
